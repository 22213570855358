import axios from "axios";
import handleApiError from "@/services/handleApiError";

export default {
    get(search) {
        return axios.get('/api/search', {
            params: {
                search: search
            }
        }).then(resp => resp.data);
    },
}
