<template>
    <section class="search-page">
        <CommonBlock
            title="Результати пошуку"
            :whiteTheme="true"
            :visible-back-btn="true"
            :breadcrumbs="breadcrumbs"
            :cancelBackBtnEventPassed="true"
            @clickBack="clickBack"
        >
            <ul class="categories">
                <li v-for="(category, index) in categories">
                    <button @click="entity = index" :class="{current: entity === index}"
                            :disabled="(index === 'all' && existsCategories.length === 1) || index !== 'all' && existsCategories.indexOf(index) === -1 || !items.length">{{ category }}
                    </button>
                </li>
            </ul>
        </CommonBlock>
        <div class="container">
            <form class="form-group" @submit.prevent="submit">
                <label>
                    <input type="text" v-model="search" tabindex="1" placeholder="Введіть слово">
                </label>
                <button></button>
            </form>
            <div class="result-container" v-if="!visiblePreloader">
                <div v-if="Object.keys(groups).length">
                    <h2>{{ count }} {{ suffixResultCounter }}</h2>
                    <div class="result-groups">
                        <div class="result-group" v-for="group in groups">
                            <h3>{{ group.title }}</h3>
                            <ul>
                                <li v-for="link in group.links">
                                    <a :href="link" target="_blank">{{ link }}</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="result-is-empty" v-else><h4>{{ error || 'По Вашому запиту нічого не знайдено' }}</h4></div>
            </div>
            <div class="preloader" v-else>Завантаження...</div>
        </div>
    </section>
</template>

<script>
import CommonBlock from "@/components/CommonBlock";
import SearchService from "@/services/SearchService";

export default {
    components: {
        CommonBlock
    },
    name: "Search",
    data() {
        return {
            counts: [],
            entity: 'all',
            visiblePreloader: true,
            originSearch: this.$route.query['search'],
            search: null,
            error: '',
            existsCategories: [],
            items: [],
            routeNames: {
                position: 'position.article',
                event: 'event',
                fileTree: 'base',
                courseCategory: 'study.index',
                course: 'course.index',
                user: 'card'
            },
            categories: {
                all: 'Усі',
                position: 'Позиція Партії',
                fileTree: 'Нормативна база',
                course: 'Навчання та можливості',
                event: 'Рекомендації та ініціативи',
                user: 'Картки депутатів'
            },
            mergeCategories: {
                courseCategory: 'course'
            },
            breadcrumbs: [
                {id: 1, name: 'Результати пошуку'},
            ],
        }
    },
    created() {
        this.search = this.$route.query['search'];
        this.entity = this.$route.query['entity'] || this.entity;
        this.setData();
    },
    computed: {
        suffixResultCounter() {
            let str = this.count.toString();
            let len = str.length;

            if (str[len - 1] > 0 && str[len - 1] < 5 && (!str[len - 2] || (str[len - 2] && +str[len - 2] !== 1))) {
                return 'співпадіння';
            } else {
                return 'співпадінь';
            }
        },
        groups() {
            let data = {};
            this.items.forEach(item => {
                let entity = item.entity;
                if (this.entity !== 'all' && this.entity !== entity) {
                    return;
                }

                let link = this.getLink(item);
                if (!data[entity]) {
                    data[entity] = {
                        title: this.categories[entity],
                        links: [link]
                    }
                } else {
                    data[entity].links.push(link);
                }
            });

            let sortedData = {};
            Object.keys(this.categories).forEach(category => {
                 if(!data[category]) {
                     return;
                 }

                 sortedData[category] = data[category];
            });

            return sortedData;
        },
        count() {
            if (!Object.keys(this.counts).length) {
                return 0;
            }
            if (this.entity === 'all') {
                return Object.values(this.counts).reduce((a, b) => +a + +b);
            }

            return this.counts[this.entity] || 0;
        }
    },
    methods: {
        getLink(item) {
            let link = this.$router.resolve({name: this.routeNames[item.originEntity], params: {id: item.id}});
            if(item.category_id) {
                link = this.$router.resolve({name: this.routeNames[item.originEntity], params: {id: item.id, category: item.category_id}});
            }

            return location.origin + link.href;
        },
        clickBack() {
            this.$router.go(-1);
        },
        submit() {
            this.setData();

            let query = `?search=${this.search}`;

            window.history.replaceState(null, null, query);
        },
        async setData() {
            this.visiblePreloader = true;
            let resp = await SearchService.get(this.search).catch(e => {
                this.error = null;
                if ('response' in e) {
                    if (e.response != null) {
                        if (e.response.status === 403) {
                            window.location = '/login';
                        }
                        if (e.response.status === 404) {
                            this.error = e.response.data.message;
                        }
                    }
                }
            }) || [];

            if (!resp['data']) {
                resp['data'] = [];
            }

            if(resp['data']['items']) {
                resp['data']['items'] = resp['data']['items'].map(item => {
                    item.originEntity = item.entity;
                    item.entity = this.mergeCategories[item.entity] || item.entity;
                    return item;
                });
                Object.keys(resp['data']['counts']).forEach(key => {
                    if(!this.mergeCategories[key]) {
                        return;
                    }

                    resp['data']['counts'][this.mergeCategories[key]] = +(resp['data']['counts'][this.mergeCategories[key]] || 0) + +resp['data']['counts'][key];
                    delete resp['data']['counts'][key];
                });
            }

            this.items = resp['data']['items'] || [];

            this.existsCategories = [];
            this.items.forEach(item => {
                if (this.existsCategories.indexOf(item.entity) === -1) {
                    this.existsCategories.push(item.entity);
                }
            });
            this.counts = (resp['data']['counts'] || 0);
            this.visiblePreloader = false;
            this.originSearch = this.search;

            if (this.existsCategories.length === 1) {
                this.entity = this.existsCategories[0];
            } else if (this.existsCategories.length !== 0 && this.existsCategories.length < Object.keys(this.categories).length) {
                this.entity = 'all';
            }
        }
    }
}
</script>

<style lang="less">
body {
    background-color: #f5f5f5;
}

@media all and(max-width: 950px) {
    .search-page .body{
        display: none!important;
    }
}

.white-theme {
    @media all and (min-width: 992px) {
        background: #fff !important;
    }

    .breadcrumb li {
        &:before {
            background: #1a2744 !important;
        }
    }

    .breadcrumb span, .body h2.title {
        color: #1a2744;
        @media all and (max-width: 992px) {
            display: none;
        }
    }
}
</style>
<style lang="less" scoped>
@defaultColor: #1a2744;
.preloader, .result-is-empty {
    text-align: center;
    color: @defaultColor;
    padding-top: 40px;
}

@media all and (max-width: 992px) {
    .categories {
        display: none;
    }
}

.categories {
    list-style: none;

    li {
        margin: 40px 0;
        @media all and (max-width: 1150px) {
            margin: 20px 0;
        }
    }

    button {
        font-size: 30px;
        display: block;
        width: 100%;
        text-align: left;
        font-weight: 600;
        line-height: 1.33;
        letter-spacing: 0.38px;
        color: @defaultColor;
        transition: color .15s linear;

        &[disabled] {
            color: #ccc !important;
            opacity: .5;
            cursor: default;
        }

        @media all and (max-width: 1150px) {
            font-size: 24px;
        }

        &.current {
            color: #ffc900;
        }
    }
}

.search-page {
    display: flex;
    @media all and (max-width: 992px) {
        display: block;
    }
}

h2 {
    margin-top: 40px;
    @media all and (max-width: 992px) {
        margin-top: 20px;
    }
}

.form-group {
    display: flex;
    margin-top: 18%;
    @media all and (max-width: 992px) {
        margin-top: 40px;
    }

    input {
        height: 56px;
        width: 100%;
        padding: 15px 20px 17px;
        border-radius: 15px;
        border: solid 1px #ffc900;
        background-color: transparent;
        font-size: 18px;
        line-height: 1.33;
        color: @defaultColor;

        &:focus {
            outline: none;
        }
    }

    label {
        display: block;
        width: 100%;
    }

    button {
        width: 56px;
        height: 56px;
        min-width: 56px;
        margin-left: 20px;
        box-shadow: 0 24px 24px -14px rgb(249 198 35 / 35%);
        display: flex;
        border-radius: 50%;
        background: #ffc900 url("../assets/images/search-black.svg") center center no-repeat;
        background-size: 16px 16px;
    }
}

.container {
    padding: 36px 80px 80px;
    width: 100%;
    background-color: #f5f5f5;
    box-sizing: border-box;
    max-height: 100vh;
    overflow-y: auto;
    @media all and (max-width: 992px) {
        width: 100%;
        padding: 0 20px 20px;
        max-height: none;
    }

    &::-webkit-scrollbar {
        width: 2px;
    }

    &::-webkit-scrollbar-track {
        background: #ffc900;
    }

    &::-webkit-scrollbar-thumb {
        background: #1a2744;
        border-radius: 0;
    }
}

.result-group {
    h3 {
        margin-top: 30px;
        font-size: 20px;
        font-weight: 700;
        line-height: 1.2;
        letter-spacing: 0.06px;
        color: @defaultColor;
    }

    ul {
        list-style: none;
    }

    a {
        color: #5082f3;
        font-size: 16px;
        line-height: 1.5;
        letter-spacing: -0.11px;
    }

    li {
        margin: 10px 0;
    }
}
</style>
